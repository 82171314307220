import image from "../tamarind1.png";

const AboutUs = () => {
  return (
    <div
      className="container"
      style={{ marginLeft: "10rem", marginRight: "10rem" }}
    >
      <h1>Aboout Us</h1>
      {/* <img src={image} alt="factory" style={{ width: "960px" }}></img> */}
      <div>
        <p>
          Welcome to Adinath Processing Industries, a leading name in the
          production of high-quality tamarind kernel powder (TKP). Our company
          is driven by the collective expertise of our three directors, who
          bring diverse backgrounds and a wealth of experience to the table.
        </p>
        <h3>Ashok Chudiwal - Director</h3>
        <p>
          With a remarkable age of 66, Ashok Chudiwal is a highly experienced
          individual in the dal mill industry. Having successfully managed
          multiple cold storage units, he possesses extensive knowledge of food
          production processes. His invaluable expertise ensures that our
          operations maintain the highest standards of quality and efficiency.
        </p>
        <h3>Abhay Gandhi - Director</h3>
        <p>
          Abhay Gandhi, aged 44, brings years of experience in pulses and grains
          trading to our company. His family business spans over 75 years, and
          he has been instrumental in its success. Abhay also oversees our
          multiple cold storages and grains cleaning and polishing plant. His
          deep-rooted presence in the city has earned his business a reputation
          for trust and reliability.
        </p>
        <h3>Yash Gugale - Director</h3>
        <p>
          As a young and dynamic partner, Yash Gugale, aged 25, injects energy
          and innovation into our company. With a keen interest in marketing and
          a knack for technological advancements, he brings fresh perspectives
          to our operations. Yash comes from a construction industry background,
          with more than 35 years of experience in his family's business. His
          expertise in marketing and innovation helps drive the growth and
          success of Adinath Processing Industries.
        </p>
        <hr />
        <p>
          Together, our directors form a formidable team, blending decades of
          experience with youthful energy and innovation. Their collective
          vision and expertise enable us to deliver exceptional products, while
          continuously adapting to the evolving needs of our customers. With
          their guidance, Adinath Processing Industries is poised to excel in
          the tamarind kernel powder industry, setting new standards of quality,
          reliability, and customer satisfaction.
        </p>
        <p>
          We take pride in our directors' accomplishments and their dedication
          to ensuring that Adinath Processing Industries remains at the
          forefront of the industry. Their leadership and expertise are the
          driving force behind our commitment to excellence and our mission to
          provide you with the finest tamarind kernel powder available.
        </p>
        <p>
          Experience the expertise and innovation of Adinath Processing
          Industries.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
