import tamrind_husk from "../tamrind_husk.jpeg";
import tamrind_seed_husk from "../tamarind_seedhusk.png";
import tamrind_seed_whole from "../tamarind_seedswhole.png";
import tamrind_seed_broken from "../tamarind_seeds_broken.png";
import tamrind_kernel_powder from "../high_quality_tamarind.png";

const Products = () => {
  return (
    <>
      <div id="products">
        <p>
          <b
            style={{
              fontSize: "55px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "2rem",
              marginTop: "4rem",
            }}
          >
            PRODUCTS
            <div
              style={{
                width: "116px",
                height: "10px",
                backgroundColor: "#41D3E1",
              }}
            ></div>
          </b>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          paddingBottom: "5rem",
          justifyContent: "center",
        }}
      >
        <div className="application-card">
          <img className="res-logo" alt="res-logo" src={tamrind_husk}></img>
          <h3>Tamarind Husk</h3>
          <p>
            Tamarind (Tamarindus indica) consists of fruit, black seeds and
            husk. Tamarind husk is outer shell of tamarind fruit obtained after
            removing the fruit, the process for obtaining tamarind husk is
            during the separation process of removing the fruit from black
            seeds.
          </p>
        </div>

        <div className="application-card">
          <img
            className="res-logo"
            alt="res-logo"
            src={tamrind_seed_husk}
          ></img>
          <h3>Tamarind Seed Husk</h3>
          <p>
            Tamarind seed husk is brown outer shell of black seeds obtained
            after decortications of roasted seeds It has wide uses in fuel and
            cattle feed industry and also tannins extracted from tamarind black
            seed husk are used in leather industry and is also used for making
            fish poison. Tamarind husk and Tamarind seed husk uses are not
            limited to know applications and usages but it has many unknown
            usages and applications. We supply good quality of tamarind
            husk/seed husk.
          </p>
        </div>

        <div className="application-card">
          <img
            className="res-logo"
            alt="res-logo"
            src={tamrind_seed_whole}
          ></img>
          <h3>Tamarind Seeds Whole</h3>
          <p>
            Tamarind white seeds are called “kernel” which is a raw material
            used in manufacturing Tamarind seed (kernel) powder. We can supply
            good quality of tamarind seeds
          </p>
        </div>

        <div className="application-card">
          <img
            className="res-logo"
            alt="res-logo"
            src={tamrind_seed_broken}
          ></img>
          <h3>Tamarind Seeds Broken</h3>
          <p>
            Tamarind white seeds are called “kernel” which is a raw material
            used in manufacturing Tamarind seed (kernel) powder. We can supply
            good quality of tamarind broken seeds
          </p>
        </div>

        <div className="application-card">
          <img
            className="res-logo"
            alt="res-logo"
            src={tamrind_kernel_powder}
          ></img>
          <h3>Tamarind Kernel Powder</h3>
          <p>
            We manufacture High quality tamarind kernel powder Grade “A” passing
            through 300 – 350 mesh, Tamarind seeds are derived from tamarind
            tree, tamarind seeds comprise of 35% husk and 65% white kernel is
            used for producing different grades of powders are called starch
            .The white kernels are rich in carbohydrates, fibres, oils and
            protein as well and also has high absorbing water capacity. Hence
            the tamarind kernel powder is 300% more efficient than corn starch.
            We manufacture tamarind kernel powder of different grades for
            different industrial uses.
          </p>
        </div>
      </div>
    </>
  );
};

export default Products;
