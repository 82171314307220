import CarouselLocal from "./CarouselLocal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../photo1.jpg";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Application from "./Application";
import Products from "./Products";
import Vision from "./Vision";

const Home = () => {
  return (
    <div className="container">
      <div className="home-heading">
        <div className="home-content">
          <div className="heading">
            Welcome to Adinath Processing Industries
          </div>
        </div>
      </div>

      {/* <CarouselLocal /> */}
      <div>
        <div className="home-description" style={{ display: "flex" }}>
          <div
            style={{
              background: "white",
              width: "50%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              // marginTop: "10%",
              textAlign: "justify",
            }}
          >
            <div
              style={{
                margin: "5rem 3rem 0 10rem",
              }}
            >
              <p>
                <b
                  style={{
                    fontSize: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // alignItems: "center",
                    paddingBottom: "2rem",
                  }}
                >
                  OUR <br /> STORY
                  <div
                    style={{
                      width: "116px",
                      height: "10px",
                      backgroundColor: "#41D3E1",
                    }}
                  ></div>
                </b>
              </p>

              <p style={{ lineHeight: "2" }}>
                {/* At Adinath Processing Industries, we are dedicated to delivering
                high-quality tamarind kernel powder (TKP) produced with utmost
                precision and expertise. As a leading manufacturer based in
                Ahmednagar, India, we have established ourselves as a trusted
                name in the industry, providing top-notch products to our valued
                customers worldwide. */}
                At Adinath Processing Industries, our unwavering commitment to
                delivering high-quality Tamarind Kernel Powder (TKP) is a
                testament to our precision and expertise in the field. As a
                prominent manufacturer headquartered in Ahmednagar, India, we
                have successfully positioned ourselves as a trusted industry
                leader, renowned for providing top-notch products to our valued
                customers worldwide. Our dedication to excellence begins with
                our meticulous production process, where every step is executed
                with precision and care. We prioritize quality at every stage of
                TKP production, ensuring that the final product meets and
                exceeds the expectations of our discerning clientele. As we
                continue to grow and innovate, our mission remains steadfast—to
                be the preferred choice for high-quality Tamarind Kernel Powder,
                offering reliability, excellence, and a commitment to customer
                satisfaction that sets us apart in the industry.
              </p>
            </div>
          </div>
          {/* <img src={image1} alt="photo1" style={{ width: "100%" }} /> */}
        </div>

        <Products />
        <div
          style={
            {
              // background: "#41D3E1",
            }
          }
        >
          <p
            style={{
              marginLeft: "10rem",
              marginRight: "10rem",
              textAlign: "left",
            }}
          >
            <b
              style={{
                fontSize: "55px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                textAlign: "left",
                paddingBottom: "2rem",
                // marginLeft: "25%",
              }}
            >
              Our Commitment to <br />{" "}
              <b style={{ color: "#41D3E1" }}>
                Quality & Sustainable Practices
              </b>
            </b>
            {/* <b> */}
            At Adinath Processing Industries, our commitment to excellence is
            evident in our stringent quality control measures applied at every
            production stage, ensuring that customers receive the finest
            Tamarind Kernel Powder in the market. Our state-of-the-art
            manufacturing facility, equipped with advanced machinery and
            technology, upholds the highest quality standards. Additionally, we
            prioritize sustainability and environmentally friendly practices.
            Our manufacturing processes are designed to minimize waste and
            optimize resource utilization. We source raw materials from trusted
            suppliers who share our dedication to sustainability. By adopting
            eco-friendly measures, we contribute to a greener future while
            upholding the highest standards of product excellence.
            {/* </b> */}
          </p>
        </div>

        {/* <p>
          <b>Sustainable Practices</b>
        </p>
        We are committed to sustainable and environmentally friendly practices.
        Our manufacturing processes are designed to minimize waste and optimize
        resource utilization. We source our raw materials from trusted suppliers
        who share our commitment to sustainability. By adopting eco-friendly
        measures, we contribute to a greener future while maintaining the
        highest standards of product excellence. */}
        {/* <p>
          <b>Customer Satisfaction</b>
        </p>
        Customer satisfaction is at the heart of everything we do. We strive to
        understand the unique requirements of our clients and provide tailored
        solutions to meet their needs. Our dedicated team of professionals works
        closely with customers, ensuring prompt delivery and excellent service.
        <p>
          <b>Contact Us</b>
        </p>
        If you have any inquiries or would like to learn more about our Tamarind
        Kernel Powder, please don't hesitate to get in touch with us. Our
        knowledgeable team is always ready to assist you. We look forward to
        serving you and building a long-lasting partnership.
        <h2>Thank you for visiting Adinath Processing Industries.</h2> */}
      </div>
      <Application />

      <ContactUs />
    </div>
  );
};
export default Home;
