const Vision = () => {
  return (
    <div className="container">
      <h1>Company Vision</h1>
      <div className="home-content">
        <p>
          At Adinath Processing Industries, our vision is to become the global
          leader in tamarind kernel powder (TKP) production, setting new
          benchmarks for quality, innovation, and sustainability. With our
          unwavering commitment to excellence and customer satisfaction, we
          aspire to establish Adinath Processing Industries as the preferred
          choice for TKP across diverse industries.
        </p>
        <p>
          Our goal is to leverage our vast experience and expertise to
          continuously improve our production processes, ensuring that our
          tamarind kernel powder remains unrivaled in purity, consistency, and
          nutritional value. Through relentless innovation and investment in
          cutting-edge technologies, we strive to push the boundaries of what
          TKP can achieve in various applications.
        </p>
        <p>
          We envision Adinath Processing Industries as a company that not only
          meets but exceeds customer expectations by providing personalized
          solutions and exceptional service. We aim to build long-lasting
          relationships with our clients, understanding their evolving needs and
          delivering products that add value to their businesses.
        </p>
        <p>
          As stewards of the environment, we are dedicated to conducting our
          operations in a sustainable and responsible manner. Our vision
          encompasses minimizing waste generation, conserving resources, and
          promoting eco-friendly practices throughout our value chain. We strive
          to be recognized as a company that not only prioritizes business
          success but also contributes positively to the well-being of the
          planet.
        </p>
        <p>
          Collaboration and partnerships are fundamental to our vision. We
          actively seek opportunities to collaborate with industry experts,
          research institutions, and like-minded organizations to foster
          innovation, drive industry advancements, and create a positive impact
          on the TKP market.
        </p>
        <p>
          Through our unwavering dedication, relentless pursuit of excellence,
          and a customer-centric approach, we envision Adinath Processing
          Industries as the foremost authority in tamarind kernel powder
          production, shaping the industry and meeting the evolving needs of our
          global clientele.
        </p>
        <p>
          Together, we will redefine the possibilities of tamarind kernel
          powder, empowering industries and individuals to unlock new levels of
          success and innovation.
        </p>
      </div>
    </div>
  );
};

export default Vision;
