import { Link } from "react-router-dom";
import logo from "./../logo.jpg";
import React from "react";
import { useState, useEffect } from "react";

const Header = () => {
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();

  return (
    <div className={`header ${scrollDirection === "down" ? "down" : "show"}`}>
      <div className="logo-cotainer">
        <img className="logo" alt="logo" src={logo} />
      </div>
      <div className="nav-items">
        <ul>
          <li>
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li>
            <a className="link" href="#contact">
              Contact Us
            </a>
          </li>
          <li>
            <a className="link" href="#products">
              Products
            </a>
          </li>
          <li>
            {/* to to be changes to /about */}
            <Link className="link" to="#">
              About Us
            </Link>
          </li>
          {/* <li>
            <Link className="link" to="/vision">
              Vision
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Header;
